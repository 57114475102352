/**
 * @file 顶部导航栏样式
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
 * @file 公用样式变量
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
* @file 顶部导航栏搜索框样式
* @author FengGuang(fengguang01@baidu.com)
*/
.paddle-header-search {
  display: inline-block;
  transition: all 0.5s cubic-bezier(0.7, 0.3, 0.1, 1);
  width: 50px;
}
.paddle-header-search-wrap {
  text-align: right;
}
.paddle-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
}
.paddle-header-search .ant-select-selection-search-input {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
  border-radius: 999999px;
}
.paddle-header-search .ant-input {
  color: #fff;
  opacity: 0;
  text-align: left;
  transition: all 0.5s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.paddle-header-search .ant-input:not(:focus) {
  background-color: transparent;
}
.paddle-header-search .ant-input:focus {
  background-color: #1527c2;
}
.paddle-header-search .ant-input:hover,
.paddle-header-search .ant-input:focus {
  border-color: rgba(255, 255, 255, 0.5);
}
.paddle-header-search .ant-input::placeholder {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0;
}
.paddle-header-search .ant-input::selection {
  background: rgba(255, 255, 255, 0.3);
}
.paddle-header-search .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: rgba(255, 255, 255, 0.5);
}
.paddle-header-search .anticon {
  color: #fff;
}
.paddle-header-search .anticon.ant-select-clear-icon {
  background: #fff;
  color: #5a68d4;
  border-radius: 999999px;
}
.paddle-header-search.paddle-header-search-visible {
  transition: all 0.5s cubic-bezier(0.7, 0.3, 0.1, 1);
  width: 100%;
}
.paddle-header-search.paddle-header-search-visible .ant-input {
  transition: all 0.5s cubic-bezier(0.7, 0.3, 0.1, 1);
  opacity: 1;
}
.paddle-header-search-input .ant-input-suffix {
  background-color: transparent;
  height: auto;
}
.paddle-header-search-input-icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(./components/header-search/image/search.png);
  display: inline-block;
  height: 14px;
  margin-top: 2px;
  width: 14px;
}
.paddle-header-search-drop {
  width: 300px;
}
.paddle-header-search-drop .ant-select-dropdown-menu {
  max-height: none;
}
.paddle-header-search-drop-item-divider {
  margin: 5px 20px 2px 20px;
  min-width: unset;
  width: auto;
}
.paddle-header-search-drop .ant-select-item-option,
.paddle-header-search-drop-item-more {
  line-height: 40px;
  padding: 0 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.paddle-header-search-drop-item-more {
  font-weight: 400;
  cursor: pointer;
}
.paddle-header-search-drop-item-type {
  float: right;
}
.paddle-header-wrap {
  font-size: 14px;
  font-weight: 400;
  position: static;
  position: sticky;
  transform: translateZ(0);
  top: 0;
  z-index: 999;
}
.paddle-header {
  font-weight: 400;
  height: 60px;
  margin: 0 30px;
  overflow: hidden;
}
.paddle-header-background {
  background-color: #1527c2;
  height: 60px;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateZ(0);
  z-index: -1;
}
.paddle-header a {
  text-decoration: none;
}
.paddle-header-logo-item {
  display: inline-block;
  height: 60px;
  overflow: hidden;
}
.paddle-header-logo-wrap {
  display: inline-block;
  width: 100%;
}
.paddle-header-logo {
  margin-top: 13px;
  width: 96px;
}
.paddle-header-menu {
  display: inline-block;
  margin: 0 -17px;
  padding: 16px 0 16px 50px;
  vertical-align: top;
}
.paddle-header-right {
  float: right;
  height: 60px;
}
.paddle-header-search-wrap {
  display: inline-block;
  line-height: 60px;
  margin-right: 10px;
  width: 280px;
  vertical-align: top;
}
.paddle-header-links-menu {
  display: inline-block;
  height: 60px;
  margin: 0 -17px 0 17px;
  padding: 16px 0 16px 0;
  vertical-align: top;
}
.paddle-header .ant-avatar .anticon {
  margin-right: 0;
}
.paddle-header .ant-menu {
  background: transparent;
  color: #fff;
  font-weight: 400;
  border-bottom: none;
  line-height: 28px;
}
.paddle-header .ant-menu > .ant-menu-item,
.paddle-header .ant-menu > .ant-menu-submenu {
  margin: 0 14px;
  padding: 0;
}
.paddle-header .ant-menu-submenu-title {
  padding: 0;
}
.paddle-header .ant-menu-item:hover,
.paddle-header .ant-menu-item-active,
.paddle-header .ant-menu:not(.ant-menu-inline),
.paddle-header .ant-menu-submenu-open,
.paddle-header .ant-menu-submenu-active,
.paddle-header .ant-menu-submenu-title,
.paddle-header .ant-menu-submenu-title:hover {
  color: #fff;
}
.paddle-header .ant-menu-item:hover > a,
.paddle-header .ant-menu-item-active > a,
.paddle-header .ant-menu:not(.ant-menu-inline) > a,
.paddle-header .ant-menu-submenu-open > a,
.paddle-header .ant-menu-submenu-active > a,
.paddle-header .ant-menu-submenu-title > a,
.paddle-header .ant-menu-submenu-title:hover > a {
  color: #fff;
}
.paddle-header .ant-menu-item,
.paddle-header .ant-menu-submenu {
  border-bottom: 3px solid transparent;
}
.paddle-header .ant-menu-item.paddle-header-user-avatar-menu-item,
.paddle-header .ant-menu-submenu.paddle-header-user-avatar-menu-item {
  border-bottom: 3px solid transparent;
  height: 27px;
  margin-top: -2px;
  vertical-align: top;
}
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #fff;
  border-bottom: 3px solid rgba(255, 255, 255, 0.3);
  font-weight: 600;
}
.paddle-header .ant-menu-item a {
  color: #fff;
}
.paddle-header .ant-menu-item a:hover {
  color: #fff;
}
.paddle-header .ant-menu-item-selected > a {
  color: #fff;
}
.paddle-header .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #fff;
}
.paddle-header-item-group-list .ant-menu-item,
.paddle-header-item-group-list .ant-menu-submenu-title {
  padding: 0;
  margin: 0;
  height: 27px;
}
.paddle-header-menu-popup {
  position: static;
  position: sticky;
  transform: translateZ(0);
  top: 60px;
  z-index: 1499;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item {
  font-weight: 500;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-active {
  background: #f7f7f7;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item,
.paddle-header-menu-popup .ant-menu .ant-menu-submenu {
  margin: 0;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-group {
  padding: 14px 0;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-group-title {
  line-height: 40px;
  padding: 0 20px;
}
.paddle-header-menu-popup .ant-menu.ant-menu-sub .ant-menu-item,
.paddle-header-menu-popup .ant-menu.ant-menu-sub .ant-menu-submenu {
  padding: 0 20px;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-divider {
  margin: 0 20px;
}
.paddle-header-submenu-popup-large {
  max-width: 100%;
  right: 0;
}
.paddle-header-submenu-popup-large .ant-menu {
  display: flex;
  padding: 22px 0 42px 55px;
  text-align: left;
}
.paddle-header-submenu-popup-large .ant-menu > * {
  flex-grow: 1;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item-group {
  float: left;
  padding: 0 15px;
  width: 225px;
  z-index: 1;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item-group-title {
  border-bottom: 1px solid #e9eaec;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  line-height: 42px;
  margin: 0 10px;
  padding: 0;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item-group-list {
  margin-top: 12px;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item {
  color: #333;
  font-weight: 500;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  padding: 0 10px;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item:hover {
  background: #f7f7f7;
  color: #1434c2;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title {
  width: 315px;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item-group-title {
  display: none;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item {
  font-size: 16px;
  font-weight: 500;
  height: 46px;
  line-height: 46px;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item:not(:hover),
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item:not(:hover) > a {
  color: #000;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item-group-list {
  margin-top: 0;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-right-icon {
  margin-left: 10px;
  transform: scale(0.65, 0.8);
}
.paddle-header-submenu-popup-large .ant-menu-hidden {
  display: none;
}
.paddle-header-submenu-popup-large-tag {
  font-size: 12px;
  font-weight: 400;
  margin-left: 10px;
}
.paddle-header-submenu-popup-large-tag.red {
  color: #ff3912;
}
.paddle-header-submenu-popup-large-tag.blue {
  color: #1a73e8;
}
.paddle-header-submenu-popup-large-tag.green {
  color: #00b359;
}
.paddle-header-submenu-popup-large-bg {
  background: #fafafa;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 21%;
  z-index: 0;
}
.paddle-header-links {
  color: #fff;
  display: inline-block;
  line-height: 60px;
  height: 60px;
  margin-left: -2px;
}
.paddle-header-links-link:after {
  display: block;
  content: attr(title);
  font-weight: 500;
  visibility: hidden;
  height: 0;
  margin-bottom: -1px;
  overflow: hidden;
}
@media screen and (max-width: 1260px) {
  .paddle-header {
    margin: 0 auto;
    width: 1200px;
  }
}
