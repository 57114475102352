.paddle-version-select {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  margin-left: 16px;
}
.paddle-version-select.ant-select-focused:not(.ant-select-customize-input).ant-select .ant-select-selector {
  border: 0;
  box-shadow: none;
}
.paddle-version-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
}
.paddle-version-select .ant-select-arrow {
  pointer-events: none;
  right: 2px;
}
.paddle-version-select .ant-select-arrow .anticon {
  pointer-events: none;
}
.paddle-version-select .ant-select-arrow-icon {
  color: #333;
  transform: scale(1.2, 1);
}
