.lite-page-arc-title {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #000;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}
.lite-page-arc-arrow-right {
  width: 12px;
  height: 20px;
  background-image: url('../../images/icon_Left.png');
  background-size: contain;
  position: absolute;
}
.lite-page-arc-analyze {
  height: 332px;
  background: #f5f5f5;
  border-radius: 2px;
  padding: 20px 20px 20px 52px;
  margin-bottom: 20px;
  position: relative;
}
.lite-page-arc-analyze-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #2932e1;
  position: absolute;
  left: 20px;
  top: 27px;
}
.lite-page-arc-analyze-line {
  width: 1px;
  height: 342px;
  background-color: #2932e1;
  position: absolute;
  left: 25px;
  top: 30px;
  z-index: 2;
}
.lite-page-arc-analyze-up {
  width: 20px;
  height: 12px;
  background-image: url('../../images/icon_up.png');
  background-size: contain;
  position: absolute;
  left: 606px;
  top: 148px;
}
.lite-page-arc-analyze-title {
  font-family: PingFangSC-Semibold;
  font-size: 18px;
  color: #000;
  letter-spacing: 0;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 10px;
}
.lite-page-arc-analyze-step1 {
  height: 84px;
  background: #fff;
  border: 1px solid #dadcdf;
  margin-bottom: 28px;
  text-align: center;
}
.lite-page-arc-analyze-step1-solid-title {
  font-size: 18px;
  color: #000;
  letter-spacing: 0;
  line-height: 84px;
  font-weight: 500;
}
.lite-page-arc-analyze-step1-hover {
  display: none;
  padding: 16px 20px;
  overflow: hidden;
  position: relative;
}
.lite-page-arc-analyze-step1-hover-content-card {
  float: left;
  width: 220px;
  height: 52px;
  background-color: #f5f5f5;
  font-size: 14px;
  color: #333;
  letter-spacing: 0;
  text-align: center;
  line-height: 24px;
  font-weight: 400;
  line-height: 52px;
}
.lite-page-arc-analyze-step1-hover-content-card:nth-child(2) {
  width: 560px;
  margin: 0 42px;
}
.lite-page-arc-analyze-step1-hover-right-1 {
  top: 36px;
  left: 255px;
}
.lite-page-arc-analyze-step1-hover-right-2 {
  top: 36px;
  left: 858px;
}
.lite-page-arc-analyze-step1:hover {
  cursor: pointer;
}
.lite-page-arc-analyze-step1:hover .lite-page-arc-analyze-step1-solid {
  display: none;
}
.lite-page-arc-analyze-step1:hover .lite-page-arc-analyze-step1-hover {
  display: block;
}
.lite-page-arc-analyze-step2 {
  height: 144px;
  background: #fff;
  border: 1px solid #dadcdf;
  text-align: center;
}
.lite-page-arc-analyze-step2-solid-title {
  font-size: 18px;
  color: #000;
  letter-spacing: 0;
  line-height: 26px;
  font-weight: 500;
  margin-top: 44px;
  margin-bottom: 8px;
}
.lite-page-arc-analyze-step2-solid-desc {
  font-size: 14px;
  color: #666;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 400;
}
.lite-page-arc-analyze-step2-hover {
  display: none;
  position: relative;
  padding: 16px 20px;
  font-size: 14px;
  color: #333;
  letter-spacing: 0;
  text-align: center;
  line-height: 24px;
  font-weight: 400;
  overflow: hidden;
}
.lite-page-arc-analyze-step2-hover-right-1 {
  left: 256px;
  top: 65px;
}
.lite-page-arc-analyze-step2-hover-right-2 {
  left: 858px;
  top: 65px;
}
.lite-page-arc-analyze-step2-hover-first,
.lite-page-arc-analyze-step2-hover-last {
  float: left;
  width: 220px;
  height: 112px;
  background-color: #f5f5f5;
  line-height: 112px;
}
.lite-page-arc-analyze-step2-hover-center {
  float: left;
  width: 558px;
  margin: 0 44px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.lite-page-arc-analyze-step2-hover-center-card {
  width: 181px;
  height: 52px;
  line-height: 52px;
  background-color: #f5f5f5;
  margin-right: 5px;
  margin-bottom: 8px;
}
.lite-page-arc-analyze-step2:hover {
  cursor: pointer;
}
.lite-page-arc-analyze-step2:hover .lite-page-arc-analyze-step2-solid {
  display: none;
}
.lite-page-arc-analyze-step2:hover .lite-page-arc-analyze-step2-hover {
  display: block;
}
.lite-page-arc-exeuate {
  height: 735px;
  background: #f5f5f5;
  border-radius: 2px;
  padding: 20px 20px 20px 52px;
  position: relative;
  overflow: hidden;
}
.lite-page-arc-exeuate-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #2932e1;
  position: absolute;
  left: 20px;
  top: 27px;
}
.lite-page-arc-exeuate-line {
  width: 1px;
  height: 619px;
  background-color: #2932e1;
  position: absolute;
  left: 25px;
  top: 30px;
}
.lite-page-arc-exeuate-title {
  font-family: PingFangSC-Semibold;
  font-size: 18px;
  color: #000;
  letter-spacing: 0;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 10px;
}
.lite-page-arc-exeuate-step1,
.lite-page-arc-exeuate-step2-step3 {
  width: 556px;
  height: 393px;
  float: left;
  background: #fff;
  border: 1px solid #dadcdf;
  margin-bottom: 16px;
}
.lite-page-arc-exeuate-step1 {
  margin-right: 16px;
}
.lite-page-arc-exeuate-step1-solid {
  display: block;
}
.lite-page-arc-exeuate-step1-solid-title {
  font-size: 18px;
  color: #0b0504;
  line-height: 28px;
  font-weight: 600;
  text-align: center;
  margin-top: 152px;
  margin-bottom: 8px;
}
.lite-page-arc-exeuate-step1-solid-desc {
  width: 400px;
  margin: auto;
  font-size: 14px;
  color: #666;
  letter-spacing: 0;
  text-align: justify;
  line-height: 24px;
  font-weight: 400;
}
.lite-page-arc-exeuate-step1-hover {
  display: none;
}
.lite-page-arc-exeuate-step1:hover {
  cursor: pointer;
}
.lite-page-arc-exeuate-step1:hover .lite-page-arc-exeuate-step1-solid {
  display: none;
}
.lite-page-arc-exeuate-step1:hover .lite-page-arc-exeuate-step1-hover {
  display: block;
  overflow: hidden;
  padding-top: 16px;
  padding-left: 20px;
}
.lite-page-arc-exeuate-step1:hover .lite-page-arc-exeuate-step1-hover-item {
  width: 167px;
  height: 176px;
  background-color: rgba(41, 50, 225, 0.1);
  float: left;
  margin-right: 8px;
  margin-bottom: 9px;
}
.lite-page-arc-exeuate-step1:hover .lite-page-arc-exeuate-step1-hover-item-word {
  font-size: 14px;
  color: #333;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 400;
  width: 58px;
  text-align: center;
  margin: auto;
  margin-top: 64px;
}
.lite-page-arc-exeuate-step1:hover .lite-page-arc-exeuate-step1-hover-item:nth-child(3n) {
  margin-right: 0;
}
.lite-page-arc-exeuate-step2-step3 {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}
.lite-page-arc-exeuate-step2-step3-solid {
  display: block;
}
.lite-page-arc-exeuate-step2-step3-solid-title {
  font-size: 18px;
  color: #000;
  letter-spacing: 0;
  line-height: 26px;
  font-weight: 500;
  padding-top: 43px;
  padding-bottom: 43px;
  border-bottom: 1px solid #dadcdf;
  text-align: center;
  margin-bottom: 112px;
}
.lite-page-arc-exeuate-step2-step3-solid-bottom {
  width: 400px;
  margin: auto;
}
.lite-page-arc-exeuate-step2-step3-solid-bottom-title {
  font-size: 18px;
  color: #000;
  line-height: 26px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 8px;
}
.lite-page-arc-exeuate-step2-step3-solid-bottom-desc {
  font-size: 14px;
  color: #666;
  text-align: justify;
  line-height: 22px;
  font-weight: 400;
}
.lite-page-arc-exeuate-step2-step3-hover {
  display: none;
}
.lite-page-arc-exeuate-step2-step3-hover-right {
  top: 50px;
  left: 272px;
}
.lite-page-arc-exeuate-step2-step3:hover {
  cursor: pointer;
  padding-top: 16px;
}
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-solid {
  display: none;
}
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover {
  display: block;
}
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover-top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #dadcdf;
  margin-bottom: 16px;
}
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover-top-item {
  width: 237px;
  height: 80px;
  background-color: #e8f2ff;
}
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover-top-item-word {
  position: relative;
  width: 172px;
  height: 48px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  color: #333;
  letter-spacing: 0;
  text-align: center;
  line-height: 24px;
  font-weight: 400;
}
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover-top-item:nth-child(1) .lite-page-arc-exeuate-step2-step3-hover-top-item-word {
  margin-top: 17px;
}
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover-top-item:nth-child(2) .lite-page-arc-exeuate-step2-step3-hover-top-item-word {
  line-height: 80px;
}
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover-middle-item {
  width: 516px;
  height: 52px;
  background-color: #e8f2ff;
  margin-bottom: 8px;
  text-align: center;
  line-height: 52px;
}
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover-bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover-bottom-item {
  width: 166px;
  height: 52px;
  background-color: #e8f2ff;
  margin-right: 8px;
  margin-bottom: 8px;
}
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover-bottom-item:nth-child(3n) {
  margin-right: 0;
}
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover-bottom-item-word {
  margin: auto;
  width: 136px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  font-size: 14px;
  color: #333;
  font-weight: 400;
}
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover-bottom-item:nth-child(4),
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover-bottom-item:nth-child(5),
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover-bottom-item:nth-child(6) {
  height: 68px;
}
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover-bottom-item:nth-child(4) .lite-page-arc-exeuate-step2-step3-hover-bottom-item-word,
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover-bottom-item:nth-child(5) .lite-page-arc-exeuate-step2-step3-hover-bottom-item-word,
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover-bottom-item:nth-child(6) .lite-page-arc-exeuate-step2-step3-hover-bottom-item-word {
  line-height: 68px;
}
.lite-page-arc-exeuate-step2-step3:hover .lite-page-arc-exeuate-step2-step3-hover-bottom-item:nth-child(5) .lite-page-arc-exeuate-step2-step3-hover-bottom-item-word {
  line-height: 24px;
  margin-top: 11px;
}
.lite-page-arc-exeuate-step4 {
  width: 1128px;
  height: 184px;
  float: left;
  background: #fff;
  border: 1px solid #dadcdf;
  margin-bottom: 20px;
}
.lite-page-arc-exeuate-step4-solid {
  display: block;
}
.lite-page-arc-exeuate-step4-solid-title {
  margin-top: 64px;
  text-align: center;
  font-size: 18px;
  color: #000;
  letter-spacing: 0;
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 8px;
}
.lite-page-arc-exeuate-step4-solid-desc {
  width: 490px;
  margin: auto;
  text-align: center;
  font-size: 14px;
  color: #666;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 400;
}
.lite-page-arc-exeuate-step4-hover {
  display: none;
  justify-content: space-between;
  flex-wrap: wrap;
}
.lite-page-arc-exeuate-step4-hover-item {
  width: 211px;
  height: 72px;
  margin-bottom: 8px;
  margin-right: 8px;
  background-color: #f5f5f5;
}
.lite-page-arc-exeuate-step4-hover-item-word {
  font-size: 14px;
  color: #333;
  letter-spacing: 0;
  text-align: center;
  line-height: 24px;
  font-weight: 400;
  width: 148px;
  margin: auto;
  margin-top: 13px;
}
.lite-page-arc-exeuate-step4-hover-item:nth-child(6),
.lite-page-arc-exeuate-step4-hover-item:nth-child(7) {
  width: 101px;
}
.lite-page-arc-exeuate-step4-hover-item:nth-child(6) .lite-page-arc-exeuate-step4-hover-item-word,
.lite-page-arc-exeuate-step4-hover-item:nth-child(7) .lite-page-arc-exeuate-step4-hover-item-word {
  width: 41px;
}
.lite-page-arc-exeuate-step4-hover-item:nth-child(1) .lite-page-arc-exeuate-step4-hover-item-word,
.lite-page-arc-exeuate-step4-hover-item:nth-child(4) .lite-page-arc-exeuate-step4-hover-item-word,
.lite-page-arc-exeuate-step4-hover-item:nth-child(7) .lite-page-arc-exeuate-step4-hover-item-word,
.lite-page-arc-exeuate-step4-hover-item:nth-child(11) .lite-page-arc-exeuate-step4-hover-item-word {
  margin-top: 25px;
}
.lite-page-arc-exeuate-step4-hover-item:nth-child(2) .lite-page-arc-exeuate-step4-hover-item-word {
  width: 180px;
  word-break: break-all;
}
.lite-page-arc-exeuate-step4:hover {
  cursor: pointer;
  padding-left: 20px;
  padding-top: 16px;
}
.lite-page-arc-exeuate-step4:hover .lite-page-arc-exeuate-step4-solid {
  display: none;
}
.lite-page-arc-exeuate-step4:hover .lite-page-arc-exeuate-step4-hover {
  display: flex;
}
.lite-page-arc-exeuate .step1-hover-to-step4 {
  padding-left: 20px;
  padding-top: 16px;
}
.lite-page-arc-exeuate .step1-hover-to-step4 .lite-page-arc-exeuate-step4-solid {
  display: none;
}
.lite-page-arc-exeuate .step1-hover-to-step4 .lite-page-arc-exeuate-step4-hover {
  display: flex;
}
.lite-page-arc-exeuate .step1-hover-to-step4 .lite-page-arc-exeuate-step4-hover .lite-page-arc-exeuate-step4-hover-item:nth-child(1),
.lite-page-arc-exeuate .step1-hover-to-step4 .lite-page-arc-exeuate-step4-hover .lite-page-arc-exeuate-step4-hover-item:nth-child(2),
.lite-page-arc-exeuate .step1-hover-to-step4 .lite-page-arc-exeuate-step4-hover .lite-page-arc-exeuate-step4-hover-item:nth-child(6),
.lite-page-arc-exeuate .step1-hover-to-step4 .lite-page-arc-exeuate-step4-hover .lite-page-arc-exeuate-step4-hover-item:nth-child(7),
.lite-page-arc-exeuate .step1-hover-to-step4 .lite-page-arc-exeuate-step4-hover .lite-page-arc-exeuate-step4-hover-item:nth-child(8) {
  background-color: #e8f2ff;
}
.lite-page-arc-exeuate .step2-step3-hover-to-step4 {
  padding-left: 20px;
  padding-top: 16px;
}
.lite-page-arc-exeuate .step2-step3-hover-to-step4 .lite-page-arc-exeuate-step4-solid {
  display: none;
}
.lite-page-arc-exeuate .step2-step3-hover-to-step4 .lite-page-arc-exeuate-step4-hover {
  display: flex;
}
.lite-page-arc-exeuate .step2-step3-hover-to-step4 .lite-page-arc-exeuate-step4-hover .lite-page-arc-exeuate-step4-hover-item:nth-child(3),
.lite-page-arc-exeuate .step2-step3-hover-to-step4 .lite-page-arc-exeuate-step4-hover .lite-page-arc-exeuate-step4-hover-item:nth-child(4),
.lite-page-arc-exeuate .step2-step3-hover-to-step4 .lite-page-arc-exeuate-step4-hover .lite-page-arc-exeuate-step4-hover-item:nth-child(5),
.lite-page-arc-exeuate .step2-step3-hover-to-step4 .lite-page-arc-exeuate-step4-hover .lite-page-arc-exeuate-step4-hover-item:nth-child(8),
.lite-page-arc-exeuate .step2-step3-hover-to-step4 .lite-page-arc-exeuate-step4-hover .lite-page-arc-exeuate-step4-hover-item:nth-child(9),
.lite-page-arc-exeuate .step2-step3-hover-to-step4 .lite-page-arc-exeuate-step4-hover .lite-page-arc-exeuate-step4-hover-item:nth-child(10),
.lite-page-arc-exeuate .step2-step3-hover-to-step4 .lite-page-arc-exeuate-step4-hover .lite-page-arc-exeuate-step4-hover-item:nth-child(11) {
  background-color: #e8f2ff;
}
.lite-page-arc-exeuate-mark {
  font-size: 14px;
  color: #666;
  letter-spacing: 0;
  font-weight: 400;
}
.lite-page-arc-exeuate-mark-item {
  margin-bottom: 4px;
}
