.lite-page-join {
  margin-bottom: 80px;
}
.lite-page-join-title {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #000;
  font-weight: 600;
  text-align: center;
}
.lite-page-join-desc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  line-height: 21px;
  font-weight: 400;
  margin-bottom: 30px;
}
.lite-page-join-card-wrapper {
  overflow: hidden;
}
.lite-page-join-card-wrapper .lite-page-join-card {
  width: 382px;
  height: 140px;
  border: 1px solid #f5f5f5;
  float: left;
  margin-right: 24px;
  background-color: #f5f5f5;
  position: relative;
  padding: 40px 130px 40px 50px;
  cursor: pointer;
}
.lite-page-join-card-wrapper .lite-page-join-card-image {
  width: 60px;
  height: 60px;
  background-size: contain;
  position: absolute;
  top: 40px;
  left: 284px;
}
.lite-page-join-card-wrapper .lite-page-join-card-title {
  font-size: 20px;
  color: #1f2024;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 10px;
}
.lite-page-join-card-wrapper .lite-page-join-card-desc {
  font-size: 16px;
  color: #666;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 400;
}
.lite-page-join-card-wrapper .lite-page-join-card:nth-child(3) {
  margin-right: 0;
}
.lite-page-join-card-wrapper .lite-page-join-card:hover {
  background: #fff;
  border: 1px solid #d8d8d8;
  box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.05);
}
.lite-page-join-card-wrapper .lite-page-join-card:hover .lite-page-join-card-title {
  color: #2931e2;
}
