/**
 * @file 文档页页面顶部tab样式
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-doc-page {
    &-top-menu {
        border-bottom: 0;
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        min-height: 49px;

        &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
            border-bottom-width: 3px;
            margin: 0 25px;
            padding: 0;
        }

        .ant-menu-item-selected {
            font-weight: 500;
        }
    }
}
