.lite-page-task {
    margin-top: 80px;

    &-title {
        font-family: PingFangSC-Semibold;
        font-size: 30px;
        color: #000;
        font-weight: 600;
        text-align: center;
        margin-bottom: 30px;
    }

    &-content {
        overflow: hidden;

        &-card {
            width: 282px;
            height: 324px;
            float: left;
            margin-right: 24px;
            cursor: pointer;
            margin-bottom: 80px;

            &-image {
                width: 282px;
                height: 148px;
            }

            &-collection {
                border: 1px solid rgba(0,0,0,0.10);
                border-bottom-left-radius: 16px;
                border-bottom-right-radius: 16px;
                border-top: none;
                height: 176px;
                padding: 20px 30px;

                &-title {
                    font-family: PingFangSC-Semibold;
                    font-size: 20px;
                    color: #000;
                    letter-spacing: 0;
                    font-weight: 600;
                    margin-bottom: 10px;
                }

                &-desc {
                    font-family: PingFangSC-Regular;
                    font-size: 14px;
                    color: rgba(51, 51, 51, .8);
                    letter-spacing: 0;
                    line-height: 24px;
                    font-weight: 400;
                    margin-bottom: 20px;
                }

                &-btn {
                    font-family: PingFangSC-Regular;
                    font-size: 14px;
                    color: #2932e1;
                    letter-spacing: 0;
                    font-weight: 500;

                    &:hover {
                        color: rgba(41, 50, 225, .8)
                    }
                }

                &:hover {
                    box-shadow: 4px 8px 12px 0 rgba(0,0,0,0.05);
                }
            }

            &:nth-child(4) {
                margin-right: 0;
            }

            &:hover {
                .lite-page-task-content-card-collection-title {
                    color: rgba(41, 50, 225);
                }
            }
        }
    }
}
@primary-color: #2932E1;