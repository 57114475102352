/**
 * @file 文档页页左侧菜单样式
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-doc-page {
    &-left-menu-wrap {
        max-height: calc(100vh - @top-flexed-height);
        overflow: hidden;
        position: static;
        // position: sticky;
        // top: @top-flexed-height;
        top: 0;

        &:hover {
            overflow-y: auto;
        }
    }

    &-left-menu {
        background: #f5f6f9;
        border-right-color: transparent;
        margin-left: 0;
        margin-right: -10px;
        padding-bottom: 10px;
        padding-top: 10px;

        .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
        &.ant-menu-inline .ant-menu-submenu-title,
        &.ant-menu-inline .ant-menu-item {
            height: auto;
            line-height: 20px;
            margin-bottom: 0;
            margin-top: 0;
            padding-bottom: 10px;
            padding-top: 10px;
            white-space: normal;
        }

        &.ant-menu-inline {
            .ant-menu-item::after {
                content: unset;
            }

            .ant-menu-sub .ant-menu-item::after {
                content: unset;
                //border-right-width: 2px;
                //bottom: 14px;
                //content: "";
                //left: 10px;
                //opacity: 0;
                //transform: scaleY(0.0001);
                //right: auto;
                //top: 14px;
            }

            .ant-menu-item-selected.ant-menu-item::after {
                //opacity: 1;
                //transform: scaleY(1);
            }
        }

        .ant-menu-submenu > .ant-menu {
            background-color: inherit;
        }

        .ant-menu-submenu-title > a,
        .ant-menu-submenu-title > span a {
            color: rgba(0, 0, 0, 0.65);
        }

        &.ant-menu .ant-menu-item-selected {
            background-color: transparent;
        }

        & &-arrow {
            background-image: url(./image/arrow.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            display: inline-block;
            height: 5px;
            margin: 3px 6px 3px -16px;
            transition: all .2s;
            width: 10px;
        }

        & .ant-menu-submenu-open > .ant-menu-submenu-title > &-arrow {
            transform: rotate(-90deg);
        }
    }
}
