.lite-page-process {
  margin-bottom: 100px;
}
.lite-page-process-title {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #000;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}
.lite-page-process .lite-page-process-card-wrapper {
  overflow: hidden;
}
.lite-page-process .lite-page-process-card-wrapper .lite-page-process-card {
  width: 300px;
  height: 204px;
  float: left;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  position: relative;
}
.lite-page-process .lite-page-process-card-wrapper .lite-page-process-card-arrow {
  width: 72px;
  height: 10px;
  background-size: contain;
  background-image: url('../../images/icon_guide-line.png');
  position: absolute;
  right: -40px;
  top: 16px;
}
.lite-page-process .lite-page-process-card-wrapper .lite-page-process-card:nth-child(4) .lite-page-process-card-arrow {
  display: none;
}
.lite-page-process .lite-page-process-card-wrapper .lite-page-process-card-image {
  width: 46px;
  height: 46px;
  background-size: contain;
}
.lite-page-process .lite-page-process-card-wrapper .lite-page-process-card-title {
  font-family: PingFangSC-Medium;
  font-size: 20px;
  color: #000;
  font-weight: 500;
  margin-top: 16px;
  margin-bottom: 8px;
}
.lite-page-process .lite-page-process-card-wrapper .lite-page-process-card-desc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  line-height: 22px;
  font-weight: 400;
}
