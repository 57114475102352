/**
 * @file 文档页页右侧锚点菜单样式
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-doc-page {
    &-right-menu-index {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding-bottom: 5px;
        padding-top: 10px;
    }

    &-right-menu-wrap {
        max-height: calc(100vh - @top-flexed-height);
        overflow: hidden;
        position: static;
        position: sticky;
        // top: @top-flexed-height;
        top: 10px;

        &:hover {
            overflow-y: auto;
        }
    }

    &-right-menu {
        border-right-color: transparent;
        font-size: 12px;
        font-weight: 400;
        margin-left: -14px;
        margin-right: -14px;

        & .ant-menu {
            font-size: 12px;
            font-weight: 400;
        }

        &.ant-menu-inline .ant-menu-submenu-title,
        &.ant-menu-inline .ant-menu-item {
            height: auto;
            line-height: 17px;
            margin-bottom: 0;
            margin-top: 0;
            padding-top: 7px;
            padding-bottom: 8px;
        }

        .ant-menu-sub.ant-menu-inline > .ant-menu-item {
            padding-bottom: 5px;
            padding-top: 5px;

            & > a {
                color: #666;
            }
        }

        .ant-menu-item,
        .ant-menu-submenu-title {
            white-space: normal;
            word-wrap: break-word;
        }

        .ant-menu-item > a,
        .ant-menu-submenu-title a {
            color: #333;
        }

        &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: inherit;
            color: @primary-color;

            a {
                color: @primary-color;
            }
        }

        .ant-menu-item-selected:after {
            content: unset;
        }
    }
}
