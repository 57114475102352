/**
 * @file 文档详情样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../../../../../node_modules/github-markdown-css/github-markdown.css';

.markdown-body .highlight pre,
.markdown-body pre {
    background-color: inherit;
}

@import '../../../../../../node_modules/highlight.js/styles/github.css';
.markdown-body.theme-code-dark {
    @import (less) '../../../../../../node_modules/highlight.js/styles/dracula.css';
}

@primary-color: #2932e1;
.markdown-body {
    font-size: 14px;
    font-weight: 400;

    a {
        color: @primary-color;
    }

    h1 {
        border-bottom: 0;
        font-size: 36px;
        font-weight: 500;
        padding-bottom: 30px;
    }

    h2 {
        font-size: 20px;
        font-weight: 500;
        border-bottom: 0;
        margin-top: 40px;
        padding-left: 13px;
        position: relative;

        &:before {
            background-color: #000;
            bottom: 0;
            content: "";
            height: 16px;
            left: 0;
            position: absolute;
            top: 5px;
            width: 3px;
        }
    }

    h3 {
        font-size: 16px;
        font-weight: 500;
        margin-top: 40px;
    }

    p {
        color: #666;
    }

    ol,
    ul {
        padding-left: 1.2em;
    }

    code {
    }

    .highlight {
        position: relative;
    }

    .btn-wrap {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .code-theme-btn {
        background-color: rgba(0, 0, 0, .1);
        background-image: url(../image/bulb.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 14px 18px;
        border-radius: 4px;
        cursor: pointer;
        display: inline-block;
        height: 30px;
        margin-left: 10px;
        width: 30px;
    }

    &.theme-code-dark .code-theme-btn {
        background-color: rgba(255, 255, 255, .1);
        background-image: url(../image/bulb-dark.png);
    }

    .code-copy-btn {
        background-color: rgba(0, 0, 0, .1);
        background-image: url(../image/markdown-copy.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 14px 14px;
        border-radius: 4px;
        cursor: pointer;
        display: inline-block;
        height: 30px;
        margin-left: 10px;
        width: 30px;
    }

    &.theme-code-dark .code-copy-btn {
        background-color: rgba(255, 255, 255, .1);
        background-image: url(../image/markdown-copy-dark.png);
    }

    .highlight pre,
    pre {
    }

    table {
        border: 0;

        th {
            background-color: #f7f7f7;
        }

        tr,
        tr:nth-child(2n) {
            background-color: inherit;
            border-color: #e0e0e0;
        }

        td, th {
            border-left: 0;
            border-right: 0;
            padding-bottom: 10px;
            padding-top: 10px;
        }

        th,
        td {
            & > p {
                margin-bottom: 0;
            }
        }
    }

    .docutils {
        color: #666;
    }

    dl:not(.docutils) {
        dt {
            background: #f2f5ff;
            border-left: solid 3px #d9e0fe;
            color: #000;
            display: inline-block;
            font-size: 14px;
            font-weight: 400;
            font-style: inherit;
            line-height: 1.5;
            margin: 6px 0;
            padding: 9px 10px 10px 20px;
            position: relative;

            code {
                background: inherit;
                margin: 0;
                padding: 0;
            }
        }
    }

    dl.function,
    dl.class {
        padding: 6px 0;

        dt {
            display: table;
            margin: 0;
        }

        dd {
            margin: 0;
        }
    }

    .headerlink {
        background-image: url(../image/link.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        color: transparent;
        display: inline-block;
        height: 19px;
        margin-left: 20px;
        opacity: 0;
        transition: all .2s;
        user-select: none;
        vertical-align: middle;
        width: 19px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #000;
    }

    h1,
    h2,
    .toctree-wrapper p.caption,
    h3,
    h4,
    h5,
    h6,
    dl dt,
    p.caption {
        &:hover > .headerlink {
            opacity: 1;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        em {
            font-style: inherit;
        }
    }

    a.hide-anchor {
        display: inline-block;
        float: left;
        height: 0;
        overflow: hidden;
        pointer-events: none;
        position: relative;
        top: -@top-flexed-height - 10px;
        user-select: none;
        z-index: -999;
    }

    hr {
        height: 1px;
    }

    .admonition {
        margin: 0 0 30px 0;

        & > * {
            background-color: #f7f7f7;
            color: #000;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 30px;
            margin: 0;
            padding: 20px;

            a {
                color: #1d2df7;
            }

            code {
                background-color: #fff;
                border: 1px solid #e5e5e5;
                border-radius: 4px;
                color: #2a2a2a;
                padding: 6px 4px;
            }
        }

        .admonition-title {
            border-left: 4px solid #b9b9b9;
            font-size: 14px;
            font-weight: 500;
            line-height: 30px;
            padding: 8px 14px;
        }

        &.note,
        &.admonition-notes {
            .admonition-title {
                border-left-color: #a3b1fb;
                background-color: #ccd4fd;
                color: #002AF7;
            }
        }

        &.warning,
        &.admonition-warnings {
            .admonition-title {
                border-left-color: #f7c0c0;
                background-color: #fbdcdc;
                color: #ec504f;
            }
        }

        &.tip,
        &.admonition-tips {
            .admonition-title {
                border-left-color: #87e8de;
                background-color: #e6fffb;
                color: #36cfc9;
            }
        }

        &.error,
        &.admonition-errors {
            .admonition-title {
                border-left-color: #d4380d;
                background-color: #ff7a45;
                color: #fff;
            }
        }

        &.attention,
        &.admonition-attentions {
            .admonition-title {
                border-left-color: #bae637;
                background-color: #f4ffb8;
                color: #7cb305;
            }
        }

        &.caution,
        &.admonition-cautions {
            .admonition-title {
                border-left-color: #f7c0c0;
                background-color: #fbdcdc;
                color: #ec504f;
            }
        }

        &.danger,
        &.admonition-dangers {
            .admonition-title {
                border-left-color: #a8071a;
                background-color: #ff4d4f;
                color: #fff;
            }
        }

        &.hint,
        &.admonition-hints {
            .admonition-title {
                border-left-color: #87e8de;
                background-color: #e6fffb;
                color: #36cfc9;
            }
        }

        &.important,
        &.admonition-importants {
            .admonition-title {
                border-left-color: #ffd591;
                background-color: #ffe7ba;
                color: #fa8c16;
            }
        }
    }
}

.markdown-body .highlight-ipython3 + .highlight-python .hljs {
    background-color: #e0edfc;

    .code-copy-btn {
        display: none;
    }
}

.markdown-body.theme-code-dark .highlight-ipython3 + .highlight-python .hljs {
    background-color: #505353;
}

