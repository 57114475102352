.lite-page-cooperate {
  margin-bottom: 60px;
}
.lite-page-cooperate-title {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #000;
  font-weight: 600;
  text-align: center;
}
.lite-page-cooperate-desc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  line-height: 21px;
  font-weight: 400;
  margin-bottom: 30px;
}
.lite-page-cooperate-card-wrapper {
  overflow: hidden;
}
.lite-page-cooperate-card-wrapper .lite-page-cooperate-card {
  width: 224px;
  height: 94px;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.lite-page-cooperate-card-wrapper .lite-page-cooperate-card-image {
  width: 224px;
  height: 94px;
  background-size: contain;
}
.lite-page-cooperate-card-wrapper .lite-page-cooperate-card:nth-child(5n) {
  margin-right: 0;
}
