.lite-page-cooperate {
    margin-bottom: 60px;

    &-title {
        font-family: PingFangSC-Semibold;
        font-size: 30px;
        color: #000;
        font-weight: 600;
        text-align: center;
    }

    &-desc {
        font-size: 14px;
        color: rgba(0, 0, 0, .8);
        text-align: center;
        line-height: 21px;
        font-weight: 400;
        margin-bottom: 30px;
    }

    &-card-wrapper {
        overflow: hidden;

        .lite-page-cooperate-card {
            width: 224px;
            height: 94px;
            float: left;
            margin-right: 20px;
            margin-bottom: 20px;
            cursor: pointer;

            &-image {
                width: 224px;
                height: 94px;
                background-size: contain;
            }

            &:nth-child(5n) {
                margin-right: 0;
            }
        }
    }
}
@primary-color: #2932E1;