.lite-page-process {
    margin-bottom: 100px;

    &-title {
        font-family: PingFangSC-Semibold;
        font-size: 30px;
        color: #000;
        font-weight: 600;
        text-align: center;
        margin-bottom: 30px;
    }

    .lite-page-process-card-wrapper {
        overflow: hidden;

        .lite-page-process-card {
            width: 300px;
            height: 204px;
            float: left;
            padding-left: 30px;
            padding-right: 30px;
            text-align: center;
            position: relative;

            &-arrow {
                width: 72px;
                height: 10px;
                background-size: contain;
                background-image: url('./../../images/icon_guide-line.png');
                position: absolute;
                right: -40px;
                top: 16px;
            }

            &:nth-child(4) {
                .lite-page-process-card-arrow {
                    display: none;
                }
            }

            &-image {
                width: 46px;
                height: 46px;
                background-size: contain;
            }

            &-title {
                font-family: PingFangSC-Medium;
                font-size: 20px;
                color: #000;
                font-weight: 500;
                margin-top: 16px;
                margin-bottom: 8px;
            }

            &-desc {
                font-size: 14px;
                color: rgba(0, 0, 0, .6);
                text-align: center;
                line-height: 22px;
                font-weight: 400;
            }
        }

    }
}
@primary-color: #2932E1;