.lite-page-news {
    margin-bottom: 80px;

    &-title {
        font-family: PingFangSC-Semibold;
        font-size: 30px;
        color: #000;
        font-weight: 600;
        text-align: center;
    }

    &-desc {
        font-size: 14px;
        color: rgba(0, 0, 0, .8);
        text-align: center;
        line-height: 21px;
        font-weight: 400;
        margin-bottom: 30px;
    }

    &-card-wrapper {
        overflow: hidden;

        .lite-page-news-desc-card {
            width: 282px;
            height: 334px;
            float: left;
            margin-right: 24px;
            margin-bottom: 20px;
            cursor: pointer;

            &-image {
                width: 282px;
                height: 210px;
                background-size: contain;
            }

            &-desc {
                height: 124px;
                border: 1px solid #d8d8d8;
                border-bottom-left-radius: 16px;
                border-bottom-right-radius: 16px;
                padding: 20px 30px;
                font-size: 18px;
                color: #000;
                letter-spacing: 0;
                line-height: 28px;
                font-weight: 400;
            }

            &:nth-child(4) {
                margin-right: 0;
            }

            &:hover .lite-page-news-desc-card-desc {
                box-shadow: 4px 8px 12px 0 rgba(0,0,0,0.05);
                color: #2921e2;
            }
        }
    }
}
@primary-color: #2932E1;