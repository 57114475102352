/**
 * @file 文档页样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../style/global';
@import './components/doc-menu/style';
@import './components/doc-article/style';

.paddle-doc-page {
	padding-top: @header-height-margin;

	.paddle-footer {
		position: relative;
		z-index: 1;
	}

	.ant-tabs-nav {
		position: fixed;
		top: 107px;
		z-index: 101;
		padding-left: 20px;
		
		.ant-tabs-tab {
			margin-right: 50px;
		}

		.ant-tabs-tab-btn {
			font-size: 16px;
		}
	}

	&-container {
		margin: 0 auto;
		//max-width: @main-max-width;
		position: relative;

		.clearfix();
	}

	&-top {
		background: #fff;
		border-bottom: 1px solid #e8e8e8;
		position: static;
		position: sticky;
		top: @header-height;
		z-index: 100;

		&-menu {
            height: 40px;
		}

		&-inner {
			margin: 0 auto;
			//max-width: @main-max-width;
			padding-left: 20px;
			padding-right: 20px;
			overflow: hidden;
			position: relative;
			height: 46px;
		}
	}

	&-version-select-wrap {
		height: 40px;

		&:after {
			content: '';
			display: inline-block;
			vertical-align: middle;
			height: 100%;
		}

		& > * {
			display: inline-block;
			vertical-align: middle;
		}
	}

	&-version-select {
		color: #333;
		font-size: 16px;
		font-weight: 500;

		&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
			border: 0;
			box-shadow: none;
			padding-left: 0;
		}

		.ant-select-arrow {
			pointer-events: none;
			right: 2px;

			.anticon {
				pointer-events: none;
			}
		}

		.ant-select-arrow-icon {
			color: #333;
			transform: scale(1.2, 1);
		}
	}

	&-lang-select-wrap {
		bottom: 0;
		position: absolute;
		right: 20px;
		top: 0;

		&:after {
			content: '';
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}

		& > * {
			display: inline-block;
			vertical-align: middle;
		}
	}

	&-lang-select {
		color: #333;
		font-size: 14px;
		font-weight: 400;
		width: 140px;

		.ant-select-single:not(.ant-select-customize-input) {
			box-shadow: unset;
		}
	}

	&-version-select-drop,
	&-lang-select-drop {
		.ant-select-item-option {
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			padding: 10px;

			a {
				color: #000;
			}
		}

		.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
			background-color: #f7f7f7;
		}

		.ant-select-item-option-selected {
			color: @primary-color;
			font-weight: 400;
			background-color: inherit;

			a {
				color: @primary-color;
			}
		}
	}

	&-main {
		min-height: calc(100vh - @top-flexed-height);
		position: relative;
		z-index: 1;
	}

	&-left-background {
		background: #f5f6f9;
		bottom: 0;
		left: -4096px - 258px;
		position: absolute;
		top: 0;
		transition: left .2s ease;
		width: 4096px + 258px;
		z-index: -1;
		position: fixed;
		top: 150px;
	}

	body.page-loading &-left-background {
		transition: none;
	}

	&-left-menu-show &-left-background {
		left: -4096px;
	}

	&-left {
		background: #f5f6f9;
		bottom: 0;
		display: inline-block;
		float: left;
		left: 0;
		margin-left: -258px;
		padding-left: 20px;
		position: absolute;
		top: 0;
		transition: margin .2s ease;
		width: 258px;
		position: fixed;
		top: 160px;
	}

	&-left-menu-show &-left {
		margin-left: 0;
	}

	&-center {
		background: #fff;
		margin: 0 180px 0 0;
		position: relative;
		transition: margin .2s ease;
	}

	&-left-menu-show &-center {
		margin-left: 258px;
	}

	&-container-no-left &-center {
		margin-left: 0;
	}

	&-container-no-left &-left-background,
	&-container-no-left &-left {
		display: none;
	}

	&-container-no-right &-right {
		display: none;
	}

	&-left-menu-switch-wrap {
		height: 0;
		position: static;
		position: sticky;
		// top: @top-flexed-height;
		top: 0;
	}

	&-left-menu-switch {
		background-color: #fff;
		background-image: url(./image/menu-show.png);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 14px 14px;
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.10);
		border-radius: 0 4px 4px 0;
		cursor: pointer;
		display: inline-block;
		height: 30px;
		width: 30px;

		&:hover,
		&:active {
			background-image: url(./image/menu-show-hover.png);
		}
	}

	&-left-menu-show &-left-menu-switch {
		background-image: url(./image/menu.png);

		&:hover,
		&:active {
			background-image: url(./image/menu-hover.png);
		}
	}

	&-breadcrumb-wrap {
		height: 60px;
		padding: 0 50px;

		&-right {
			float: right;
			height: 100%;

			&:after {
				content: '';
				display: inline-block;
				height: 100%;
				vertical-align: middle;
			}

			& > * {
				display: inline-block;
				vertical-align: middle;
			}
		}

		&:after {
			content: '';
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}

		& > * {
			display: inline-block;
			vertical-align: middle;
		}
	}

	&-content {
		padding: 0 50px 50px 50px;
	}

	&-right {
		bottom: 0;
		display: inline-block;
		position: absolute;
		right: 0;
		// top: 0;
		width: 200px;
		z-index: 1;
		position: fixed;
		top: 160px;
	}

	.paddle-doc-alias-tags-label {
		color: #000;
		cursor: pointer;
		display: block;
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 24px;
		margin-bottom: 20px;
		vertical-align: top;
	}

	.paddle-doc-alias-tags-label-icon {
		background-image: url('./image/alias-opened.png');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		display: inline-block;
		margin-right: 10px;
		height: 24px;
		width: 24px;
		vertical-align: top;
	}

	.paddle-doc-alias-tags-label.open .paddle-doc-alias-tags-label-icon {
		background-image: url('./image/alias-closed.png');
	}

	.paddle_doc_api_alias_tags {
		color: #000;
		height: 0;
		overflow: hidden;

		p {
			color: #000;
		}

		a {
			background: #f2f5ff;
			margin: 0 -5px;
			padding: 0 5px;
		}

		.update_api {
			margin-block-start: 1em;
			margin-block-end: 1em;
		}

		.migration_guide {
			margin-block-start: 1em;
			margin-block-end: 1em;
		}
	}

	.paddle-doc-alias-tags-label.open + .paddle_doc_api_alias_tags {
		height: auto;
	}

	&-right-menu-wrap {
		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}

		&::-webkit-scrollbar-button {
			background-color: rgba(0, 0, 0, 0);
		}

		&::-webkit-scrollbar-track {
			background-color: rgba(0, 0, 0, 0);
		}

		&::-webkit-scrollbar-track-piece {
			background-color: rgba(0, 0, 0, 0);
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgba(0, 0, 0, 0);
		}

		&::-webkit-scrollbar-corner {
			background-color: rgba(0, 0, 0, 0);
		}

		&::-webkit-scrollbar-resizer {
			background-color: rgba(0, 0, 0, 0);
		}

		/*o内核*/

		&::-o-scrollbar {
			-moz-appearance: none !important;
			background: rgba(0, 255, 0, 0) !important;
		}

		&::-o-scrollbar-button {
			background-color: rgba(0, 0, 0, 0);
		}

		&::-o-scrollbar-track {
			background-color: rgba(0, 0, 0, 0);
		}

		&::-o-scrollbar-track-piece {
			background-color: rgba(0, 0, 0, 0);
		}

		&::-o-scrollbar-thumb {
			background-color: rgba(0, 0, 0, 0);
		}

		&::-o-scrollbar-corner {
			background-color: rgba(0, 0, 0, 0);
		}

		&::-o-scrollbar-resizer {
			background-color: rgba(0, 0, 0, 0);
		}

		/*IE10,IE11,IE12*/
		-ms-scroll-chaining: chained;
		-ms-overflow-style: none;
		-ms-content-zooming: zoom;
		-ms-scroll-rails: none;
		-ms-content-zoom-limit-min: 100%;
		-ms-content-zoom-limit-max: 500%;
		-ms-scroll-snap-type: proximity;
		-ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);
	}
}

@primary-color: #2932E1;