/**
 * @file 文档详情样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import './docstyle';

a.hide-anchor {
    display: inline-block;
    float: left;
    height: 0;
    overflow: hidden;
    pointer-events: none;
    position: relative;
    top: 0;
    user-select: none;
    z-index: -999;
}
