.lite-page-banner {
    margin-top: 54px;
    margin-bottom: 80px;
    border-bottom: 1px solid rgba(0, 0, 0, .3);

    &-title {
        font-size: 45px;
        color: #000;
        line-height: 67px;
        font-weight: 600;
        margin-bottom: 6px;
    }

    &-desc {
        font-size: 16px;
        color: #000;
        line-height: 26px;
        font-weight: 400;
        margin-bottom: 30px;
    }

    &-btn {
        display: flex;
        margin-bottom: 60px;

        &-item {
            width: 140px;
            height: 48px;
            background: #2932e1;
            border-radius: 25px;
            text-align: center;
            line-height: 48px;
            font-size: 18px;
            color: #fff;
            letter-spacing: 0;
            font-weight: 600;
            margin-right: 20px;
            cursor: pointer;

            &:hover {
                background: #515eed;
            }
        }
    }
}
@primary-color: #2932E1;