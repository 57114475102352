/**
* @file 顶部导航栏搜索框样式
* @author FengGuang(fengguang01@baidu.com)
*/


.paddle-header-search {
    display: inline-block;
    transition: all 0.5s cubic-bezier(0.7, 0.3, 0.1, 1);
    width: 50px;

    &-wrap {
        text-align: right;
    }


    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        background-color: transparent;
    }

    .ant-select-selection-search-input {
        background-color: transparent;
        border-color: @search-border-color;
        border-radius: 999999px;
    }

    .ant-input {
        color: #fff;
        opacity: 0;
        text-align: left;
        transition: all 0.5s cubic-bezier(0.7, 0.3, 0.1, 1);

        &:not(:focus) {
            background-color: transparent;
        }

        &:focus {
            background-color: #1527c2;
        }

        &:hover,
        &:focus {
            border-color: @search-border-color;
        }
    }

    .ant-input::placeholder {
        font-weight: 400;
        color: rgba(255, 255, 255, .5);
        letter-spacing: 0;
    }

    .ant-input::selection {
        background: rgba(255, 255, 255, .3);
    }

    .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
        border-color: @search-border-color;
    }

    .anticon {
        color: #fff;

        &.ant-select-clear-icon {
            background: #fff;
            color: #5a68d4;
            border-radius: 999999px;
        }
    }

    &.paddle-header-search-visible {
        transition: all 0.5s cubic-bezier(0.7, 0.3, 0.1, 1);
        width: 100%;

        .ant-input {
            transition: all 0.5s cubic-bezier(0.7, 0.3, 0.1, 1);
            opacity: 1;
        }
    }

    &-input .ant-input-suffix {
        background-color: transparent;
        height: auto;
    }

    &-input-icon {
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(./image/search.png);
        display: inline-block;
        height: 14px;
        margin-top: 2px;
        width: 14px;
    }

    &-drop {
        width: 300px;

        .ant-select-dropdown-menu {
            max-height: none;
        }

        &-item-divider {
            margin: 5px 20px 2px 20px;
            min-width: unset;
            width: auto;
        }

        .ant-select-item-option,
        &-item-more {
            line-height: 40px;
            padding: 0 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-item-more {
            font-weight: 400;
            cursor: pointer;
        }

        &-item-type {
            float: right;
        }
    }
}
