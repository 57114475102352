.paddle-lite-demo-wrapper {
    width: 1200px;
    margin: auto;
    margin-top: 60px;

    .paddle-lite-demo-title {
        font-size: 36px;
        color: #000;
        letter-spacing: 0;
        line-height: 44px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .paddle-lite-demo-desc {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #666;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 40px;
    }

    .paddle-lite-demo-card-wrapper {
        overflow: hidden;

        .paddle-lite-demo-card {
            width: 380px;
            border: 1px solid #e5e5e5;
            margin-right: 30px;
            margin-bottom: 24px;
            float: left;
            border-radius: 16px;

            &-content {
                margin: 20px 50px 0;
                height: 156px;
            }

            &-img {
                width: 378px;
                height: 226px;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
                background-size: contain;
                cursor: pointer;
            }

            &-title {
                font-family: PingFangSC-Medium;
                font-size: 22px;
                color: #000;
                letter-spacing: 1px;
                line-height: 38px;
                font-weight: 500;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-bottom: 10px;
            }

            &-desc {
                width: 280px;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #999;
                letter-spacing: 0;
                line-height: 24px;
                font-weight: 500;
                display: inline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            &:nth-child(3n) {
                margin-right: 0;
            }

            &:hover {
                box-shadow: 4px 8px 12px 0px rgba(0,0,0,0.05);
                .paddle-lite-demo-card-content {
                    .paddle-lite-demo-card-title {
                        cursor: pointer;
                        color: #2932e1;
                    }
                }
            }
        }
    }
}

.paddle-lite-demo-type-wrap {

    border: none;
    margin-bottom: 30px;
    .ant-radio-button-wrapper {
        border: none;
        margin-right: 24px;

        &-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
            box-shadow: none;
        }

        &:first-child {
            border: none;
        }

        .ant-radio-button-checked {
            background-color: #1a73e8;
        }
    }

    .ant-radio-button-wrapper::before {
        display: none;
    }

    .ant-form-item-label {
        margin-right: 20px;

        label {
            position: relative;
            top: -1px;
            color: #999;

            &::after {
                display: none;
            }
        }
    }

    .ant-form-item:first-child {
        margin-right: 76px;
    }
}
@primary-color: #2932E1;