.paddle-version-select {
    color: #333;
    font-size: 16px;
    font-weight: 500;
    margin-left: 16px;

    &.ant-select-focused:not(.ant-select-customize-input).ant-select .ant-select-selector {
        border: 0;
        box-shadow: none;
    }

    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 0;
    }

    .ant-select-arrow {
        pointer-events: none;
        right: 2px;

        .anticon {
            pointer-events: none
        }
    }

    .ant-select-arrow-icon {
        color: #333;
        transform: scale(1.2, 1);
    }
}

@primary-color: #2932E1;