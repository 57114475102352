/**
 * @file 页脚样式
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
 * @file 公用样式变量
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-footer {
  background: #000;
  color: #f0f0f0;
  display: block;
  min-height: 403px;
}
.paddle-footer-container {
  max-width: 1200px;
  min-height: 353px;
  margin: 0 auto;
  overflow: hidden;
  padding: 85px 0;
}
.paddle-footer-group {
  width: 191px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}
.paddle-footer-group-title {
  font-size: 18px;
}
.paddle-footer-item-list {
  float: left;
  margin-top: 25px;
}
.paddle-footer-item,
.paddle-footer-item a {
  color: #f0f0f0;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
}
.paddle-footer-qr-wrap {
  float: right;
  margin-bottom: -11px;
  margin-right: -55px;
}
.paddle-footer-qr-item {
  display: inline-block;
  margin-right: 55px;
  vertical-align: top;
  width: 140px;
}
.paddle-footer-qr-context {
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  height: 140px;
  overflow: hidden;
  width: 140px;
}
.paddle-footer-qr-context-img {
  opacity: 0;
  width: 100%;
}
.paddle-footer-qr-title {
  color: #7b7b7b;
  font-size: 14px;
  line-height: 14px;
  margin-top: 14px;
  text-align: center;
  vertical-align: top;
}
.paddle-footer-qr-subtitle {
  font-size: 12px;
}
.paddle-footer-bottom {
  background: #000;
  border-top: 1px solid #242424;
  color: #999;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
