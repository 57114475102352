.lite-page-arc {

    &-title {
        font-family: PingFangSC-Semibold;
        font-size: 30px;
        color: #000;
        font-weight: 600;
        text-align: center;
        margin-bottom: 30px;
    }

    &-arrow-right {
        width: 12px;
        height: 20px;
        background-image: url('./../../images/icon_Left.png');
        background-size: contain;
        position: absolute;
    }

    &-analyze {
        height: 332px;
        background: #f5f5f5;
        border-radius: 2px;
        padding: 20px 20px 20px 52px;
        margin-bottom: 20px;
        position: relative;

        &-circle {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #2932e1;
            position: absolute;
            left: 20px;
            top: 27px;
        }

        &-line {
            width: 1px;
            height: 342px;
            background-color: #2932e1;
            position: absolute;
            left: 25px;
            top: 30px;
            z-index: 2;
        }

        &-up {
            width: 20px;
            height: 12px;
            background-image: url('./../../images/icon_up.png');
            background-size: contain;
            position: absolute;
            left: 606px;
            top: 148px;
        }

        &-title {
            font-family: PingFangSC-Semibold;
            font-size: 18px;
            color: #000;
            letter-spacing: 0;
            line-height: 26px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        &-step1 {
            height: 84px;
            background: #fff;
            border: 1px solid #dadcdf;
            margin-bottom: 28px;
            text-align: center;

            &-solid {
                &-title {
                    font-size: 18px;
                    color: #000;
                    letter-spacing: 0;
                    line-height: 84px;
                    font-weight: 500;
                }
            }

            &-hover {
                display: none;
                padding: 16px 20px;
                overflow: hidden;
                position: relative;

                &-content {
                    &-card {
                        float: left;
                        width: 220px;
                        height: 52px;
                        background-color: #f5f5f5;
                        font-size: 14px;
                        color: #333;
                        letter-spacing: 0;
                        text-align: center;
                        line-height: 24px;
                        font-weight: 400;
                        line-height: 52px;
    
                        &:nth-child(2) {
                            width: 560px;
                            margin: 0 42px;
                        }
                    }
                }

                &-right-1 {
                    top: 36px;
                    left: 255px;
                }

                &-right-2 {
                    top: 36px;
                    left: 858px;
                }
            }

            &:hover {
                cursor: pointer;

                .lite-page-arc-analyze-step1-solid {
                    display: none;
                }

                .lite-page-arc-analyze-step1-hover {
                    display: block;
                }
            }
        }

        &-step2 {
            height: 144px;
            background: #fff;
            border: 1px solid #dadcdf;
            text-align: center;

            &-solid {
                &-title {
                    font-size: 18px;
                    color: #000;
                    letter-spacing: 0;
                    line-height: 26px;
                    font-weight: 500;
                    margin-top: 44px;
                    margin-bottom: 8px;
                }
    
                &-desc {
                    font-size: 14px;
                    color: #666;
                    letter-spacing: 0;
                    line-height: 22px;
                    font-weight: 400;
                }
            }

            &-hover {
                display: none;
                position: relative;
                padding: 16px 20px;
                font-size: 14px;
                color: #333;
                letter-spacing: 0;
                text-align: center;
                line-height: 24px;
                font-weight: 400;
                overflow: hidden;

                &-right-1 {
                    left: 256px;
                    top: 65px;
                }

                &-right-2 {
                    left: 858px;
                    top: 65px;
                }

                &-first,
                &-last {
                    float: left;
                    width: 220px;
                    height: 112px;
                    background-color: #f5f5f5;
                    line-height: 112px;
                }

                &-center {
                    float: left;
                    width: 558px;
                    margin: 0 44px;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    &-card {
                        width: 181px;
                        height: 52px;
                        line-height: 52px;
                        background-color: #f5f5f5;
                        margin-right: 5px;
                        margin-bottom: 8px;
                    }
                }

            }

            &:hover {
                cursor: pointer;

                .lite-page-arc-analyze-step2-solid {
                    display: none;
                }

                .lite-page-arc-analyze-step2-hover {
                    display: block;
                }
            }
        }
    }

    &-exeuate {
        height: 735px;
        background: #f5f5f5;
        border-radius: 2px;
        padding: 20px 20px 20px 52px;
        position: relative;
        overflow: hidden;

        &-circle {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #2932e1;
            position: absolute;
            left: 20px;
            top: 27px;
        }

        &-line {
            width: 1px;
            height: 619px;
            background-color: #2932e1;
            position: absolute;
            left: 25px;
            top: 30px;
        }

        &-title {
            font-family: PingFangSC-Semibold;
            font-size: 18px;
            color: #000;
            letter-spacing: 0;
            line-height: 26px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        &-step1,
        &-step2-step3 {
            width: 556px;
            height: 393px;
            float: left;
            background: #fff;
            border: 1px solid #dadcdf;
            margin-bottom: 16px;
        }

        &-step1 {
            margin-right: 16px;

            &-solid {
                display: block;
                &-title {
                    font-size: 18px;
                    color: #0b0504;
                    line-height: 28px;
                    font-weight: 600;
                    text-align: center;
                    margin-top: 152px;
                    margin-bottom: 8px;
                }
    
                &-desc {
                    width: 400px;
                    margin: auto;
                    font-size: 14px;
                    color: #666;
                    letter-spacing: 0;
                    text-align: justify;
                    line-height: 24px;
                    font-weight: 400;
                }
            }

            &-hover {
                display: none;
            }

            &:hover {
                cursor: pointer;
                .lite-page-arc-exeuate-step1-solid {
                    display: none;
                }
                .lite-page-arc-exeuate-step1-hover {
                    display: block;
                    overflow: hidden;
                    padding-top: 16px;
                    padding-left: 20px;
                    &-item {
                        width: 167px;
                        height: 176px;
                        background-color: rgba(41, 50, 225, .1);
                        float: left;
                        margin-right: 8px;
                        margin-bottom: 9px;

                        &-word {
                            font-size: 14px;
                            color: #333;
                            letter-spacing: 0;
                            text-align: center;
                            line-height: 24px;
                            font-weight: 400;
                            width: 58px;
                            text-align: center;
                            margin: auto;
                            margin-top: 64px;
                        }

                        &:nth-child(3n) {
                            margin-right: 0;
                        }
                    }
                }
            }

        }

        &-step2-step3 {
            padding-left: 20px;
            padding-right: 20px;
            position: relative;

            &-solid {
                display: block;
                &-title {
                    font-size: 18px;
                    color: #000;
                    letter-spacing: 0;
                    line-height: 26px;
                    font-weight: 500;
                    padding-top: 43px;
                    padding-bottom: 43px;
                    border-bottom: 1px solid #dadcdf;
                    text-align: center;
                    margin-bottom: 112px;
                }
    
                &-bottom {
                    width: 400px;
                    margin: auto;
    
                    &-title {
                        font-size: 18px;
                        color: #000;
                        line-height: 26px;
                        font-weight: 500;
                        text-align: center;
                        margin-bottom: 8px;
                    }
    
                    &-desc {
                        font-size: 14px;
                        color: #666;
                        text-align: justify;
                        line-height: 22px;
                        font-weight: 400;
                    }
                }
            }

            &-hover {
                display: none;
                &-right {
                    top: 50px;
                    left: 272px;
                }
            }

            &:hover {
                cursor: pointer;
                padding-top: 16px;

                .lite-page-arc-exeuate-step2-step3-solid {
                    display: none;
                }

                .lite-page-arc-exeuate-step2-step3-hover {
                    display: block;

                    &-top {
                        display: flex;
                        justify-content: space-between;
                        padding-bottom: 16px;
                        border-bottom: 1px solid #dadcdf;
                        margin-bottom: 16px;

                        &-item {
                            width: 237px;
                            height: 80px;
                            background-color: #e8f2ff;

                            &-word {
                                position: relative;
                                width: 172px;
                                height: 48px;
                                left: 50%;
                                transform: translateX(-50%);
                                font-size: 14px;
                                color: #333;
                                letter-spacing: 0;
                                text-align: center;
                                line-height: 24px;
                                font-weight: 400;
                            }

                            &:nth-child(1) .lite-page-arc-exeuate-step2-step3-hover-top-item-word {
                                margin-top: 17px;
                            }

                            &:nth-child(2) .lite-page-arc-exeuate-step2-step3-hover-top-item-word {
                                line-height: 80px;
                            }
                        }
                    }

                    &-middle-item {
                        width: 516px;
                        height: 52px;
                        background-color: #e8f2ff;
                        margin-bottom: 8px;
                        text-align: center;
                        line-height: 52px;
                    }

                    &-bottom {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        &-item {
                            width: 166px;
                            height: 52px;
                            background-color: #e8f2ff;
                            margin-right: 8px;
                            margin-bottom: 8px;

                            &:nth-child(3n) {
                                margin-right: 0;
                            }

                            &-word {
                                margin: auto;
                                width: 136px;
                                height: 48px;
                                text-align: center;
                                line-height: 48px;
                                font-size: 14px;
                                color: #333;
                                font-weight: 400;
                            }

                            &:nth-child(4),
                            &:nth-child(5),
                            &:nth-child(6) {
                                height: 68px;
                                .lite-page-arc-exeuate-step2-step3-hover-bottom-item-word {
                                    line-height: 68px;
                                }
                            }

                            &:nth-child(5) .lite-page-arc-exeuate-step2-step3-hover-bottom-item-word {
                                line-height: 24px;
                                margin-top: 11px;
                            }
                        }
                    }
                }
            }

            
        }

        &-step4 {
            width: 1128px;
            height: 184px;
            float: left;
            background: #fff;
            border: 1px solid #dadcdf;
            margin-bottom: 20px;

            &-solid {
                display: block;
                &-title {
                    margin-top: 64px;
                    text-align: center;
                    font-size: 18px;
                    color: #000;
                    letter-spacing: 0;
                    line-height: 26px;
                    font-weight: 500;
                    margin-bottom: 8px;
                }
    
                &-desc {
                    width: 490px;
                    margin: auto;
                    text-align: center;
                    font-size: 14px;
                    color: #666;
                    letter-spacing: 0;
                    line-height: 22px;
                    font-weight: 400;
                }
            }

            &-hover {
                display: none;
                justify-content: space-between;
                flex-wrap: wrap;

                &-item {
                    width: 211px;
                    height: 72px;
                    margin-bottom: 8px;
                    margin-right: 8px;
                    background-color: #f5f5f5;


                    &-word {
                        font-size: 14px;
                        color: #333;
                        letter-spacing: 0;
                        text-align: center;
                        line-height: 24px;
                        font-weight: 400;
                        width: 148px;
                        margin: auto;
                        margin-top: 13px;
                    }

                    &:nth-child(6),
                    &:nth-child(7) {
                        width: 101px;
                        .lite-page-arc-exeuate-step4-hover-item-word {
                            width: 41px;
                        }
                    }

                    &:nth-child(1),
                    &:nth-child(4),
                    &:nth-child(7),
                    &:nth-child(11) {
                        .lite-page-arc-exeuate-step4-hover-item-word {
                            margin-top: 25px;
                        }
                    }

                    &:nth-child(2) .lite-page-arc-exeuate-step4-hover-item-word {
                        width: 180px;
                        word-break: break-all;
                    }
                }
            }

            &:hover {
                cursor: pointer;
                padding-left: 20px;
                padding-top: 16px;
                .lite-page-arc-exeuate-step4-solid {
                    display: none;
                }
                .lite-page-arc-exeuate-step4-hover {
                    display: flex;
                }
            }
        }

        .step1-hover-to-step4 {
            padding-left: 20px;
            padding-top: 16px;
            .lite-page-arc-exeuate-step4-solid {
                display: none;
            }
            .lite-page-arc-exeuate-step4-hover {
                display: flex;

            .lite-page-arc-exeuate-step4-hover-item {
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(6),
                    &:nth-child(7),
                    &:nth-child(8) {
                        background-color: #e8f2ff;
                    }
                }

            }
        }

        .step2-step3-hover-to-step4 {
            padding-left: 20px;
            padding-top: 16px;
            .lite-page-arc-exeuate-step4-solid {
                display: none;
            }
            .lite-page-arc-exeuate-step4-hover {
                display: flex;

                .lite-page-arc-exeuate-step4-hover-item {
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(8),
                    &:nth-child(9),
                    &:nth-child(10),
                    &:nth-child(11) {
                        background-color: #e8f2ff;
                    }
                }
            }
        }

        &-mark {
            font-size: 14px;
            color: #666;
            letter-spacing: 0;
            font-weight: 400;

            &-item {
                margin-bottom: 4px;
            }
        }
    }
}
@primary-color: #2932E1;