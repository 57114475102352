/**
 * @file 顶部导航栏样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../style/define';
@import './components/header-search/style';


.paddle-header-wrap {
    font-size: 14px;
    font-weight: 400;
    position: static;
    position: sticky;
    transform: translateZ(0);
    top: 0;
    z-index: 999;
}

.paddle-header {
    font-weight: 400;
    height: @header-height;
    margin: 0 30px;
    overflow: hidden;
    // width: @main-max-width;


    &-background {
        background-color: #1527c2;
        height: @header-height;
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateZ(0);
        z-index: -1;
    }

    a {
        text-decoration: none;
    }

    &-logo-item {
        display: inline-block;
        height: @header-height;
        overflow: hidden;
    }

    &-logo-wrap {
        display: inline-block;
        width: 100%;
    }

    &-logo {
        margin-top: 13px;
        width: 96px;
    }

    &-menu {
        display: inline-block;
        margin: 0 -17px;
        padding: 16px 0 16px 50px;
        vertical-align: top;
    }

    &-right {
        float: right;
        height: @header-height;
    }

    &-search-wrap {
        display: inline-block;
        line-height: @header-height;
        margin-right: 10px;
        width: 280px;
        vertical-align: top;
    }

    &-links-menu {
        display: inline-block;
        height: @header-height;
        margin: 0 -17px 0 17px;
        padding: 16px 0 16px 0;
        vertical-align: top;
    }

    .ant-avatar .anticon {
        margin-right: 0;
    }
}

.paddle-header {
    .ant-menu {
        background: transparent;
        color: #fff;
        font-weight: 400;

        > .ant-menu-item,
        > .ant-menu-submenu {
            margin: 0 14px;
            padding: 0;
        }

        &-submenu-title {
            padding: 0;
        }

        &-item:hover,
        &-item-active,
        &:not(.ant-menu-inline),
        &-submenu-open,
        &-submenu-active,
        &-submenu-title,
        &-submenu-title:hover {
            color: #fff;

            > a {
                color: #fff;
            }
        }

        border-bottom: none;
        line-height: 28px;

        &-item,
        &-submenu {
            border-bottom: 3px solid transparent;

            &.paddle-header-user-avatar-menu-item {
                border-bottom: 3px solid transparent;
                height: 27px;
                margin-top: -2px;
                vertical-align: top;
            }
        }

        &.ant-menu-horizontal:not(.ant-menu-dark) {
            & > .ant-menu-item:hover,
            & > .ant-menu-submenu:hover,
            & > .ant-menu-item-active,
            & > .ant-menu-submenu-active,
            & > .ant-menu-item-open,
            & > .ant-menu-submenu-open,
            & > .ant-menu-item-selected,
            & > .ant-menu-submenu-selected {
                color: #fff;
                border-bottom: 3px solid rgba(255, 255, 255, 0.3);
                font-weight: 600;
            }
        }

        &-item {
            a {
                color: #fff;

                &:hover {
                    color: #fff;
                }
            }

            &-selected > a {
                color: #fff;
            }
        }
    }

    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
        color: #fff;
    }

    &-item-group-list {
        .ant-menu-item,
        .ant-menu-submenu-title {
            padding: 0;
            margin: 0;
            height: 27px;
        }
    }
}

.paddle-header-menu-popup {
    position: static;
    position: sticky;
    transform: translateZ(0);
    top: @header-height;
    z-index: 1499;

    .ant-menu {
        .ant-menu-item {
            font-weight: 500;
        }

        .ant-menu-item-active {
            background: #f7f7f7;
        }

        .ant-menu-item,
        .ant-menu-submenu {
            margin: 0;
        }

        .ant-menu-item-group {
            padding: 14px 0;
        }

        .ant-menu-item-group-title {
            line-height: 40px;
            padding: 0 20px;
        }

        &.ant-menu-sub {
            .ant-menu-item,
            .ant-menu-submenu {
                padding: 0 20px;
            }
        }

        .ant-menu-item-divider {
            margin: 0 20px;
        }
    }
}

.paddle-header-submenu-popup-large {
    max-width: 100%;
    right: 0;

    .ant-menu {
        display: flex;
        padding: 22px 0 42px 55px;
        text-align: left;

        & > * {
            flex-grow: 1;
        }

        .ant-menu-item-group {
            //display: inline-block;
            float: left;
            padding: 0 15px;
            width: 225px;
            z-index: 1;
        }

        .ant-menu-item-group-title {
            border-bottom: 1px solid #e9eaec;
            color: #000;
            font-weight: 500;
            font-size: 16px;
            line-height: 42px;
            margin: 0 10px;
            padding: 0;
        }

        .ant-menu-item-group-list {
            margin-top: 12px;
        }

        .ant-menu-item {
            color: #333;
            font-weight: 500;
            font-size: 14px;
            height: 30px;
            line-height: 30px;
            margin: 0;
            padding: 0 10px;

            &:hover {
                background: #f7f7f7;
                color: #1434c2;
            }
        }

        .paddle-header-submenu-popup-group-no-title {
            width: 315px;

            .ant-menu-item-group-title {
                display: none;
            }

            .ant-menu-item {
                font-size: 16px;
                font-weight: 500;
                height: 46px;
                line-height: 46px;

                &:not(:hover),
                &:not(:hover) > a {
                    color: #000
                }
            }

            .ant-menu-item-group-list {
                margin-top: 0;
            }
        }

        .paddle-header-submenu-popup-group-right-icon {
            margin-left: 10px;
            transform: scale(.65, .8);
        }
    }

    .ant-menu-hidden {
        display: none;
    }

    &-tag {
        font-size: 12px;
        font-weight: 400;
        margin-left: 10px;

        &.red {
            color: #ff3912;
        }

        &.blue {
            color: #1a73e8;
        }

        &.green {
            color: #00b359;
        }
    }

    &-bg {
        background: #fafafa;
        bottom: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 21%;
        z-index: 0;
    }
}

.paddle-header-links {
    color: #fff;
    display: inline-block;
    line-height: @header-height;
    height: @header-height;
    margin-left: -2px;

    // 使用伪元素撑开宽度，使hover的时候不会抖动
    &-link:after {
        display: block;
        content: attr(title);
        font-weight: 500;
        visibility: hidden;
        height: 0;
        margin-bottom: -1px;
        overflow: hidden;
    }
}

@media screen and (max-width: 1260px) {
    .paddle-header {
        margin: 0 auto;
        width: @main-max-width;
    }
}

@primary-color: #2932E1;